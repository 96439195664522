<template>
  <div id="footer">
    <div>
      <h2 style="color: white">Gympods</h2>
      <p><a @click="openFaqs">FAQs</a></p>
      <p><a @click="contact">CONTACT US</a></p>
      <p><a @click="terms">T&Cs</a></p>
      <p><a @click="privacy">PRIVACY POLICY</a></p>
      <p style="font-size: 10px; text-transform: none; letter-spacing: 2px">&copy; The FIT POD Company Ltd</p>
      <br />
      <p>Powered by <a style="color: #2596be" href="https://bookedsolid.co.uk?source=gympods">BookedSolid</a></p>
    </div>

    <div>
      <h3 style="color: white">Follow</h3>
      <div style="font-size: 2.8em; padding-top: 8px; padding-left: 0; color: white">
        <a style="color: white" href="https://instagram.com/gympods/">
          <i style="color: white" class="fa-brands fa-instagram"></i></a>
        <a href="https://www.facebook.com/gympods">
          <i class="fa-brands fa-facebook"></i></a>
        <a href="https://www.twitter.com/gympods">
          <i class="fa-brands fa-twitter"></i></a>
        <a href="https://www.tiktok.com/@gympods">
          <i class="fa-brands fa-tiktok"></i></a>
      </div>

      <h3 style="color: white">Get the app</h3>
      <div style="font-size: 2.8em; padding-top: 8px; padding-left: 0; color: white; justify-content: space-around;">
        <a style="padding: 10px;" href='https://play.google.com/store/apps/details?id=com.gympods.app'>
          <img width="150px" class="android" alt='Get it on Google Play'
            src='../assets/googleplay.png' />
        </a>
        <a style="padding: 10px;" href="https://apps.apple.com/gb/app/gympods/id6502242401">
          <img width="150px" class="apple"
            src="../assets/appstore.png"
            alt="Download on the App Store">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
      width: window.innerWidth,
    };
  },
  methods: {
    openFaqs() {
      window.location.href = '/about?faqs=true';
    },
    contact() {
      this.$router.push('/contact');
    },
    terms() {
      this.$router.push('/terms');
    },
    privacy() {
      this.$router.push('/privacy');
    },
  },
}
</script>

<style lang="scss" scoped>
#footer {
  text-transform: uppercase;
  color: white;
  font-size: 0.8em;
  line-height: 1.875em;
  letter-spacing: 0.2em;
  display: block;
  background-color: #231F20;
  display: flex;
  width: 100vw;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: space-between;

  div {
    display: block;
    text-align: left;
    width: 50vw;
    padding: 50px;
    box-sizing: border-box;

    i {
      margin: 20px;
    }
  }

  a {
    cursor: pointer;
  }

  a:hover {
    text-shadow: 0 0 10px #EFAB7D;
  }
}

@media screen and (max-width: 820px) {
  #footer {
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      margin: 50px 0;
    }

    div {
      width: unset;
      min-width: 320px;
      padding: 20px;
      text-align: left;
    }
  }
}
</style>