<template>
  <div class="LandingHeaderDiv" style="position: relative">

    <div class="carousel">
      <img v-if="this.slide !== 1 && this.slide !== 2 && this.slide !== 3 && this.slide !== 4 && this.slide !== 5"
        id="slideZero" src="../assets/images/DSCF1263.jpg" style="object-fit: cover; object-position: 85% 80%;">
      <div id="tint"></div>
      <div :class="{ 'active': this.slide == 1, 'inactive': this.slide != 1 }" id="slideOne"></div>
      <div :class="{ 'active': this.slide == 2, 'inactive': this.slide != 2 }" id="slideTwo"></div>
      <div :class="{ 'active': this.slide == 3, 'inactive': this.slide != 3 }" id="slideThree"></div>
      <div :class="{ 'active': this.slide == 4, 'inactive': this.slide != 4 }" id="slideFour"></div>
      <div :class="{ 'active': this.slide == 5, 'inactive': this.slide != 5 }" id="slideFive"></div>
    </div>

    <div>
      <div v-if="this.slide == 1" class="LandingHeader" style="padding-top: 150px; margin-top: 80px">
        Unique<br />fitness<br /> concept<br />
        <button class="LandingHeaderButton" @click="goToBook">Book a POD</button>
      </div>
      <div v-if="this.slide == 2" class="LandingHeader" style="padding-top: 150px; margin-top: 80px">
        private<br /> workout<br />pods<br />
        <button class="LandingHeaderButton" @click="goToBook">Book a POD</button>
      </div>
      <div v-if="this.slide == 3" class="LandingHeader" style="padding-top: 150px; margin-top: 80px">
        ideal for<br />personal<br /> trainers<br />
        <button class="LandingHeaderButton" @click="goToBook">Book a POD</button>
      </div>
      <div v-if="this.slide == 4" class="LandingHeader" style="padding-top: 150px; margin-top: 80px">
        5 &starf;<br /><span style="text-transform: underline !important" @click="goToReviews">google<br />
          reviews</span><br />
        <button class="LandingHeaderButton" @click="goToBook">Book a POD</button>
      </div>
      <div v-if="this.slide == 5" class="LandingHeader" style="padding-top: 150px; margin-top: 80px">
        personal<br />on demand<br /> space<br />
        <button class="LandingHeaderButton" @click="goToBook">Book a POD</button>
      </div>
      <div v-if="this.slide !== 1 && this.slide !== 2 && this.slide !== 3 && this.slide !== 4 && this.slide !== 5"
        class="LandingHeader" style="padding-top: 150px; margin-top: 80px">
        Personal<br />on demand<br /> space<br />
        <button class="LandingHeaderButton" @click="goToBook">Book a POD</button>
      </div>
    </div>

  </div>

  <div class="containerFlex" style="padding: 100px 0px;">

    <div id="intro" style="justify-items: left;"><br />
      <h3 style="color: #7fc0c3;">DISCOVER GYMPODS <br />UNIQUE AWARD WINNING PRIVATE FITNESS SPACES!</h3>
      <p>With 14 spacious, custom built, private PODs packed with the latest Functional & Cardio gym kit.
      </p><br />
      <p>Train Solo, with a Buddy or as a Personal Trainer with clients. <br />Experience your best workouts ever.
      </p><br />
      <p>Pay as you train packages or monthly plans. <br />No joining fees or contracts.
      </p><br />
      <p>Try GYMPODS and book our Taster Pack. <br />Just £15 for 2 x POD sessions.
      </p>
      <br />

      <button class="bookBtn" @click="this.$router.push('/book')">Get Taster Pack</button>
    </div>

  </div>

  <div class="singleColumn" id="facilities">
    <div style="flex: 0 1 250px; display: flex; align-items: center;">
      <img src="../assets/gp_index_facilities_1.png" style="width: 88px; margin: 20px;">
      <div>Private<br>Space</div>
    </div>
    <div style="flex: 0 1 250px; display: flex; align-items: center;">
      <img src="../assets/gp_index_facilities_2.png" style="width: 88px; margin: 20px;">
      <span>Clean</span>
    </div>
    <div style="flex: 0 1 250px; display: flex; align-items: center;">
      <img src="../assets/gp_index_facilities_3.png" style="width: 88px; margin: 20px;">
      <span>Safe &<br>Secure</span>
    </div>
    <div style="flex: 0 1 250px; display: flex; align-items: center;">
      <img src="../assets/gp_index_facilities_4.png" style="width: 85px; margin: 20px;">
      <span>No Waiting For Kit</span>
    </div>
  </div>

  <div class="singleColumn" id="launch">
    <p style="margin: 30px 0; color: white">"GYMPODS IS THE SOLUTION TO GYM-TIMIDATION" <br /> - Men's Fitness, 2023</p>
  </div>

  <div class="singleColumn" id="classpass">
    <a href="https://classpass.com/studios/gympods-london">
      <img alt="ClassPass Partner"
        src="https://classpass.com/blog/wp-content/uploads/2024/03/BestOfClassPass2023_GradientLong.png" />
    </a>
  </div>

  <div class="carousel width twoColumnsSquare" id="podTypeCarousel">
    <div class="twoColumns square"
      :class="{ 'carouselActive': this.carousel == 1, 'carouselInactive': this.carousel != 1 }"
      style="background: #EFAB7D;">
      <div style="text-align: center;" class="podTypeIcon">
        <img class="svg-to-white" style="width: 50%;" src="../assets/gp_pod_holistic.svg">
        <h3 style="color: white; margin: 30px 0 0 0;">HOLISTIC</h3>
      </div>
      <img src="../assets/HOLISTIC_HOME.jpg" class="podTypeImg" />
    </div>
    <div class="twoColumns square"
      :class="{ 'carouselActive': this.carousel == 2, 'carouselInactive': this.carousel != 2 }"
      style="background: #7fc0c3;">
      <div style="text-align: center;" class="podTypeIcon">
        <img class="svg-to-white" style="width: 50%;" src="../assets/gp_pod_run.svg">
        <h3 style="color: white; margin: 30px 0 0 0;">RUN</h3>
      </div>
      <img class="podTypeImg" style="object-position: 100% 50%;" src="../assets/images/DSCF1172.jpg" />
    </div>
    <div class="twoColumns square"
      :class="{ 'carouselActive': this.carousel == 3, 'carouselInactive': this.carousel != 3 }"
      style="background: #EFAB7D;">
      <div style="text-align: center;" class="podTypeIcon">
        <img class="svg-to-white" style="width: 50%;" src="../assets/gp_pod_strength.svg">
        <h3 style="color: white; margin: 30px 0 0 0;">STRENGTH</h3>
      </div>
      <img class="podTypeImg" style="object-position: 60% 90%;" src="../assets/images/DSCF1222.jpg" />
    </div>
    <div class="twoColumns square"
      :class="{ 'carouselActive': this.carousel == 4, 'carouselInactive': this.carousel != 4 }"
      style="background: #7fc0c3;">
      <div style="text-align: center;" class="podTypeIcon">
        <img class="svg-to-white" style="width: 50%;" src="../assets/gp_pod_hiit.svg">
        <h3 style="color: white; margin: 30px 0 0 0;">POWER</h3>
      </div>
      <img class="podTypeImg" style="object-position: 100% 50%;" src="../assets/images/DSCF1078.jpg" />
    </div>
    <!-- <div class="twoColumns square"
      :class="{ 'carouselActive': this.carousel == 5, 'carouselInactive': this.carousel != 5 }"
      style="background: #EFAB7D;">
      <div style="text-align: center;" class="podTypeIcon">
        <img class="svg-to-white" style="width: 50%;" src="../assets/gp_pod_row.svg">
        <h3 style="color: white; margin: 30px 0 0 0;">ROW</h3>
      </div>
      <img class="podTypeImg" src="../assets/row.jpg" />
    </div> -->
    <div class="twoColumns square"
      :class="{ 'carouselActive': this.carousel == 5, 'carouselInactive': this.carousel != 5 }"
      style="background: #7fc0c3;">
      <div style="text-align: center;" class="podTypeIcon">
        <img class="svg-to-white" style="width: 50%;" src="../assets/gp_pod_cycle.svg">
        <h3 style="color: white; margin: 30px 0 0 0;">CYCLE</h3>
      </div>
      <img class="podTypeImg" style="object-position: 0% 60%;" src="../assets/images/DSCF0921.jpg" />
    </div>
  </div>

  <div class="twoColumns" id="summaryBoxes">

    <div @click="toggleClickBoxOne">
      <div v-if="this.clickBoxOne === false" id="clickBoxOne">
        <div id="clickBoxOneInner">FIT FOR<br />YOUR<br />WORKOUT</div>
      </div>

      <div v-else style="background: #7fc0c3;" id="clickBoxOneEx">
        <div id="clickBoxOneInnerEx">
          <div>
            <p>
              Our PODS are fitted with<br />everything you need<br />to workout.
            </p><br />
            <p>
              Pay as you train, with<br /> no monthly contract.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div @click="toggleClickBoxTwo">
      <div v-if="this.clickBoxTwo === false" id="clickBoxTwo">
        <div id="clickBoxTwoInner">FIT FOR<br />PERSONAL<br />TRAINERS</div>
      </div>

      <div v-else style="background: #231F20;" id="clickBoxTwoEx">
        <div id="clickBoxTwoInnerEx">
          <div>
            <p>
              10 PODS suited to Personal<br />Training fitted with<br />everything you and<br />your clients need.
            </p><br />
            <p>
              PT packages suited to adoc<br /> or regular sessions.
            </p><br />
            <p>
              Enquire at <a style="text-decoration: underline !important"
                href="mailto:pt@gympods.com">pt@gympods.com</a>.
            </p><br />
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="singleColumn" id="pricesCarousel">

    <div class="carousel">
      <img class="square pricesCarousel width"
        :class="{ 'carouselPricesActive': this.carousel == 1, 'carouselInactive': this.carousel != 1 }"
        src="../assets/CORRIDOR.jpg" />
      <img class="square pricesCarousel width"
        :class="{ 'carouselPricesActive': this.carousel == 2, 'carouselInactive': this.carousel != 2 }"
        src="../assets/images/DSCF1263.jpg" />
      <img class="square pricesCarousel width"
        :class="{ 'carouselPricesActive': this.carousel == 3, 'carouselInactive': this.carousel != 3 }"
        src="../assets/CORRIDOR.jpg" />
      <img class="square pricesCarousel width"
        :class="{ 'carouselPricesActive': this.carousel == 4, 'carouselInactive': this.carousel != 4 }"
        src="../assets/images/DSCF1263.jpg" />
      <img class="square pricesCarousel width"
        :class="{ 'carouselPricesActive': this.carousel == 5, 'carouselInactive': this.carousel != 5 }"
        src="../assets/CORRIDOR.jpg" />
      <img class="square pricesCarousel width"
        :class="{ 'carouselPricesActive': this.carousel == 6, 'carouselInactive': this.carousel != 6 }"
        src="../assets/images/DSCF1263.jpg" />
    </div>

    <a id="pricesBtn" class="whiteBtn" @click="goToBook">Book a POD</a>

  </div>

  <PageFooter />
</template>

<script>
import PageFooter from '@/components/PageFooter.vue'

export default {
  name: 'HomeView',
  components: {
    PageFooter,
  },
  data() {
    return {
      slide: 1,
      carousel: 1,
      clickBoxOne: false,
      clickBoxTwo: false,
    };
  },
  mounted() {
    this.slide = setInterval(() => {
      if (this.slide < 5) this.slide++;
      else this.slide = 1;
    }, 4000);
    this.carousel = setInterval(() => {
      if (this.carousel < 5) this.carousel++;
      else this.carousel = 1;
    }, 3000);
  },
  methods: {
    goToBook() {
      this.$router.push('/book');
    },
    goToFind() {
      this.$router.push('/find');
    },
    goToAbout() {
      this.$router.push('/about');
    },
    goToReviews() {
      window.location.href = 'https://goo.gl/maps/K5EgBDLmXDe3ott5A';
    },
    toggleClickBoxOne() {
      this.clickBoxOne = !this.clickBoxOne;
    },
    toggleClickBoxTwo() {
      this.clickBoxTwo = !this.clickBoxTwo;
    },
  },
  beforeUnmount() {
    clearInterval(this.slide);
  },
}
</script>

<style lang="scss" scoped>
#slideZero,
#slideOne,
#slideTwo,
#slideThree,
#slideFour,
#slideFive {
  width: 100vw;
  height: calc(100vh - 80px);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 2;
}

#tint {
  width: 100vw;
  height: calc(100vh - 80px);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 80px;
  left: 0;
  background: #000000;
  opacity: 0.5;
  z-index: 3;
}

.hidden {
  display: none;
}

.inactive {
  opacity: 0;
}

@keyframes fadeImageIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.active {
  animation-name: fadeImageIn;
  animation-duration: 4s;
}

#slideOne {
  background-image: url(../assets/CORRIDOR.jpg);
}

#slideTwo {
  background-image: url(../assets/images/DSCF1197.jpg);
  background-position: 50% 100%;
}

#slideThree {
  background-image: url(../assets/images/DSCF1263.jpg);
  background-position: 85% 80%;
}

#slideFour {
  background-image: url(../assets/images/DSCF1142.jpg);
  background-position: 50% 10%;
}

#slideFive {
  background-image: url(../assets/images/DSCF3344\ 1.jpg);
  object-fit: cover;
  background-position: 40% 100%;
}

#facilities {
  background: #7fc0c3;
  min-height: 200px;
  display: flex;
  color: white;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-wrap: wrap;
  padding: 40px 0px;
}

#intro {
  width: 60vw;
  text-align: left;
  font-size: 1.2em;
}

.containerFlex {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

.bookBtn {
  font-family: 'Inter';
  background: none;
  border: 3px solid #7fc0c3;
  color: #7fc0c3;
  padding: 7.5px 30px;
  font-size: 1em;
  letter-spacing: 0.1em;
  font-weight: bold;
  cursor: pointer;
}

.bookBtn:hover {
  background: #7fc0c3;
  color: white;
}

#launch,
#classpass {
  background-image: linear-gradient(90deg, #7fc0c3, #DFF496);
  min-height: 30px;
  color: white;
  font-size: 1.5em;
  padding-top: 20px;
  line-height: 1.5em;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding: 40px 0;
}

#classpass {
  background: #231F20;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 400px;
  }
}

.svg-to-white {
  filter: invert(100%) sepia(99%) saturate(2%) hue-rotate(67deg) brightness(104%) contrast(100%);
}

#podTypeCarousel {
  position: relative;
  max-height: 720px;
}

.carouselActive {
  opacity: 1;
}

.carouselInactive {
  opacity: 0;
}

.square {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1.5s;
}

.podTypeImg {
  width: 50vw;
  height: 50vw;
  max-height: 720px;
  max-width: 720px;
  object-fit: cover;
}

#clickBoxOne,
#clickBoxTwo {
  background: #231F20;
  width: 50vw;
  height: 50vw;
  max-width: 720px;
  max-height: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  font-weight: bold;
  letter-spacing: 0.2em;
  cursor: pointer;
  margin: -10px;
}

#clickBoxTwo {
  background: #EFAB7D;
}

#clickBoxOneInner,
#clickBoxTwoInner {
  width: 25vw;
  max-width: 360px;
  height: 25vw;
  max-height: 360px;
  text-align: center;
  background-color: #7fc0c3;
  display: flex;
  align-items: center;
  justify-content: center;
}

#clickBoxTwoInner {
  background-color: #231F20;
  color: #EFAB7D;
}

#clickBoxOneEx,
#clickBoxTwoEx {
  background: #231F20;
  width: 75%;
  height: 75%;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  color: white;
  font-size: 1em;
  letter-spacing: 0.2em;
  padding: 0 25px;
  box-sizing: border-box;
}

#clickBoxOneEx,
#clickBoxTwoEx {
  background: #7fc0c3;
  color: white;
  width: 50vw;
  max-width: 720px;
  height: 50vw;
  max-height: 720px;
  margin: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#clickBoxTwoEx {
  background: #231F20;
  color: #231F20;
}

#clickBoxOneInnerEx,
#clickBoxTwoInnerEx {
  width: 40vw;
  height: 40vw;
  max-height: 650px;
  text-align: center;
  background-color: #231F20;
  display: flex;
  align-items: center;
  justify-content: center;
}

#clickBoxTwoInnerEx {
  background-color: #EFAB7D;
  color: #231F20;
}

#pricesCarousel {
  padding: 20px;
  height: 80vh;
  width: 100vw;
  background-color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselPricesActive {
  opacity: 0.5;
}

.pricesCarousel {
  width: 100vw;
  height: 80vh;
  object-fit: cover;
}

#pricesBtn {
  z-index: 5;
}

@media screen and (max-width: 820px) {

  #slideZero,
  #slideOne,
  #slideTwo,
  #slideThree,
  #slideFour,
  #slideFive,
  #tint {
    height: 500px;
  }

  #tint {
    opacity: 0.3;
  }

  #intro {
    width: 80vw;
    margin-top: 30px;
  }

  #intro p {
    margin-top: 25px;
    text-align: justify;
  }

  .podTypeImg {
    width: 100vw;
    height: 100vw;
  }

  .podTypeIcon {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .twoColumns.square>div {
    width: 300px;
    height: unset;
    margin: 50px auto;
    display: block;
    position: relative;
  }

  #summaryBoxes {
    margin-top: calc(100vw + 324px);
  }

  #summaryBoxes div {
    padding: 0;
  }

  .carousel.twoColumnsSquare {
    height: unset;
  }

  #clickBoxOne,
  #clickBoxTwo,
  #clickBoxOneEx,
  #clickBoxTwoEx {
    width: 100vw;
    height: 100vw;
    margin: 0;
    padding: 0;
  }

  #clickBoxOneInner,
  #clickBoxTwoInner {
    width: 60%;
    height: 60%;
  }

  #clickBoxOneInnerEx,
  #clickBoxTwoInnerEx {
    width: 90vw;
    height: 90vw;
  }

  #clickBoxOneInnerEx p {
    margin-top: 23vw;
  }

  #clickBoxTwoInnerEx p {
    margin-top: 12vw;
  }

  #pricesCarousel,
  .pricesCarousel {
    height: 300px;
  }
}
</style>